// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
//import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "@hotwired/turbo-rails"
import "channels"
import "jquery"
import jquery from "jquery"

Rails.start()
//Turbolinks.start()
ActiveStorage.start()

jQuery(document).on('turbo:load', function() {
  console.log('Red 5 standing by');
  jquery('body').addClass('js');

  // Alert Dismiss
  jQuery('.alert').on('click', 'button', function(e){
    e.preventDefault();
    jQuery(this).parent().addClass('alert--dismiss');
  });

  // Search Modal
  jQuery('.search_modal_toggle').on('click', function(e){
    e.preventDefault();
    jQuery('#search').fadeIn();
    jQuery('.bg_blur').fadeIn();
  });

  jQuery('.search__close').on('click', function(e){
    e.preventDefault();
    jQuery('#search').fadeOut();
    jQuery('.bg_blur').fadeOut();
  });

  // Nav Modal
  jQuery('.nav_modal_toggle').on('click', function(e){
    e.preventDefault();
    jQuery('#nav').addClass('nav--active');
    jQuery('.bg_blur').fadeIn();
  });

  jQuery('.nav__close').on('click', function(e){
    e.preventDefault();
    jQuery('#nav').removeClass('nav--active');
    jQuery('.bg_blur').fadeOut();
  });

  jQuery('.bg_blur').on('click', function(){
    jQuery('#search').fadeOut();
    jQuery('#nav').removeClass('nav--active');
    jQuery('.bg_blur').fadeOut();
    jQuery('.book_card__rate_form').fadeOut();
    jQuery('.modal').fadeOut();
  });

  // Rating toggle
  jQuery('.book_card__rating .button').on('click', function(e){
    e.preventDefault();
    jQuery(this).closest('.book_card').children('.book_card__rate_form').fadeIn();
    jQuery('.bg_blur').fadeIn();
  });

  jQuery('.book_card__rate_form .button--small').on('click', function(){
    jQuery('.book_card__rate_form').fadeOut();
    jQuery('.bg_blur').fadeOut();
  });

  // Follow Button Modal
  jQuery('.button--follow-modal').on('click', function(e){
    e.preventDefault();
    jQuery(this).siblings('.modal').fadeIn();
    jQuery('.bg_blur').fadeIn();
  });

});